import { default as _91_91code_93_934bQm8JxpyWMeta } from "/var/www/portal/pages/[[code]].vue?macro=true";
import { default as indexkFTexCEWWNMeta } from "/var/www/portal/pages/auth/login/index.vue?macro=true";
import { default as otpYdLgt3gIrUMeta } from "/var/www/portal/pages/auth/login/otp.vue?macro=true";
import { default as logout0FnljhwxrQMeta } from "/var/www/portal/pages/auth/logout.vue?macro=true";
import { default as entrepriseGBjxP6wD2lMeta } from "/var/www/portal/pages/auth/register/entreprise.vue?macro=true";
import { default as indexdqFvaFeL7bMeta } from "/var/www/portal/pages/auth/register/index.vue?macro=true";
import { default as personneW9Oh3vURu8Meta } from "/var/www/portal/pages/auth/register/personne.vue?macro=true";
import { default as _91code_936CYXRyDrIuMeta } from "/var/www/portal/pages/cart/checkout/[code].vue?macro=true";
import { default as indexoFBj88vIa0Meta } from "/var/www/portal/pages/cart/index.vue?macro=true";
import { default as indexbTar8Uc5RDMeta } from "/var/www/portal/pages/certificates/index.vue?macro=true";
import { default as indexWPTMp3zRjmMeta } from "/var/www/portal/pages/document-library/index.vue?macro=true";
import { default as indexum74pTkUfeMeta } from "/var/www/portal/pages/file-status/index.vue?macro=true";
import { default as helloworld7wctF5FPt5Meta } from "/var/www/portal/pages/helloworld.vue?macro=true";
import { default as indexQMRukbV2hfMeta } from "/var/www/portal/pages/inbox/index.vue?macro=true";
import { default as indexE55kDS4rA7Meta } from "/var/www/portal/pages/membres/index.vue?macro=true";
import { default as _91id_936zU1Kt45K9Meta } from "/var/www/portal/pages/my-cars-copy/[id].vue?macro=true";
import { default as _91id_9333c4Xkm9jsMeta } from "/var/www/portal/pages/my-cars/[id].vue?macro=true";
import { default as indexREVjwr48C6Meta } from "/var/www/portal/pages/my-cars/index.vue?macro=true";
import { default as profileEzyZIzFBpeMeta } from "/var/www/portal/pages/profile.vue?macro=true";
import { default as indexUPWqrteaThMeta } from "/var/www/portal/pages/purchases/index.vue?macro=true";
import { default as _91id_93Trh89YmDp2Meta } from "/var/www/portal/pages/services/depot-de-titre/[id].vue?macro=true";
import { default as _91id_939wtSz3tj6gMeta } from "/var/www/portal/pages/services/depot-de-titre/edit/[id].vue?macro=true";
import { default as _91id_935kpKKURescMeta } from "/var/www/portal/pages/services/immatriculation-number-prestige/[id].vue?macro=true";
import { default as _91id_93TXGmDIokVtMeta } from "/var/www/portal/pages/services/immatriculation-number-prestige/edit/[id].vue?macro=true";
import { default as _91id_93KnjSshL9hXMeta } from "/var/www/portal/pages/services/immatriculation-number/[id].vue?macro=true";
import { default as _91id_93xzCf0N5YTrMeta } from "/var/www/portal/pages/services/immatriculation-number/edit/[id].vue?macro=true";
import { default as _91id_93fB6FaKaV8CMeta } from "/var/www/portal/pages/services/immatriculation-prestige-label/[id].vue?macro=true";
import { default as _91id_93YOLDHfBNsoMeta } from "/var/www/portal/pages/services/immatriculation-prestige-label/edit/[id].vue?macro=true";
import { default as _91id_93LrJP3kvLwSMeta } from "/var/www/portal/pages/services/immatriculation-prestige-numero-label/[id].vue?macro=true";
import { default as _91id_93kBTAA0gpFmMeta } from "/var/www/portal/pages/services/immatriculation-prestige-numero-label/edit/[id].vue?macro=true";
import { default as _91id_93O7NIMDbWc1Meta } from "/var/www/portal/pages/services/mutation/[id].vue?macro=true";
import { default as _91id_93uxRQlqyWlMMeta } from "/var/www/portal/pages/services/mutation/edit/[id].vue?macro=true";
import { default as _91id_93f7cAdibQNYMeta } from "/var/www/portal/pages/services/reimmatriculation/[id].vue?macro=true";
import { default as _91id_93whL2AYqY07Meta } from "/var/www/portal/pages/services/reimmatriculation/edit/[id].vue?macro=true";
import { default as _91id_93U923wSDH3fMeta } from "/var/www/portal/pages/services/reprise-de-titre/[id].vue?macro=true";
import { default as _91id_93EjbPoR6MU5Meta } from "/var/www/portal/pages/services/reprise-de-titre/edit/[id].vue?macro=true";
import { default as _91id_936HaLVgfxrZMeta } from "/var/www/portal/pages/services/sales-declaration/[id].vue?macro=true";
import { default as _91id_93sEHlqRbPPNMeta } from "/var/www/portal/pages/services/sales-declaration/edit/[id].vue?macro=true";
import { default as _91id_93ydRuW5h4bKMeta } from "/var/www/portal/pages/services/vehicule-transformation/[id].vue?macro=true";
import { default as _91id_93BSxlhk86SMMeta } from "/var/www/portal/pages/services/vehicule-transformation/edit/[id].vue?macro=true";
import { default as facturesqpys2kjqyeMeta } from "/var/www/portal/pages/transactions/factures.vue?macro=true";
import { default as indexzH9QjGEbcjMeta } from "/var/www/portal/pages/transactions/index.vue?macro=true";
import { default as paiementstUt64tkdlWMeta } from "/var/www/portal/pages/transactions/paiements.vue?macro=true";
export default [
  {
    name: _91_91code_93_934bQm8JxpyWMeta?.name ?? "code",
    path: _91_91code_93_934bQm8JxpyWMeta?.path ?? "/:code?",
    meta: _91_91code_93_934bQm8JxpyWMeta || {},
    alias: _91_91code_93_934bQm8JxpyWMeta?.alias || [],
    redirect: _91_91code_93_934bQm8JxpyWMeta?.redirect,
    component: () => import("/var/www/portal/pages/[[code]].vue").then(m => m.default || m)
  },
  {
    name: indexkFTexCEWWNMeta?.name ?? "auth-login",
    path: indexkFTexCEWWNMeta?.path ?? "/auth/login",
    meta: indexkFTexCEWWNMeta || {},
    alias: indexkFTexCEWWNMeta?.alias || [],
    redirect: indexkFTexCEWWNMeta?.redirect,
    component: () => import("/var/www/portal/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: otpYdLgt3gIrUMeta?.name ?? "auth-login-otp",
    path: otpYdLgt3gIrUMeta?.path ?? "/auth/login/otp",
    meta: otpYdLgt3gIrUMeta || {},
    alias: otpYdLgt3gIrUMeta?.alias || [],
    redirect: otpYdLgt3gIrUMeta?.redirect,
    component: () => import("/var/www/portal/pages/auth/login/otp.vue").then(m => m.default || m)
  },
  {
    name: logout0FnljhwxrQMeta?.name ?? "auth-logout",
    path: logout0FnljhwxrQMeta?.path ?? "/auth/logout",
    meta: logout0FnljhwxrQMeta || {},
    alias: logout0FnljhwxrQMeta?.alias || [],
    redirect: logout0FnljhwxrQMeta?.redirect,
    component: () => import("/var/www/portal/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: entrepriseGBjxP6wD2lMeta?.name ?? "auth-register-entreprise",
    path: entrepriseGBjxP6wD2lMeta?.path ?? "/auth/register/entreprise",
    meta: entrepriseGBjxP6wD2lMeta || {},
    alias: entrepriseGBjxP6wD2lMeta?.alias || [],
    redirect: entrepriseGBjxP6wD2lMeta?.redirect,
    component: () => import("/var/www/portal/pages/auth/register/entreprise.vue").then(m => m.default || m)
  },
  {
    name: indexdqFvaFeL7bMeta?.name ?? "auth-register",
    path: indexdqFvaFeL7bMeta?.path ?? "/auth/register",
    meta: indexdqFvaFeL7bMeta || {},
    alias: indexdqFvaFeL7bMeta?.alias || [],
    redirect: indexdqFvaFeL7bMeta?.redirect,
    component: () => import("/var/www/portal/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: personneW9Oh3vURu8Meta?.name ?? "auth-register-personne",
    path: personneW9Oh3vURu8Meta?.path ?? "/auth/register/personne",
    meta: personneW9Oh3vURu8Meta || {},
    alias: personneW9Oh3vURu8Meta?.alias || [],
    redirect: personneW9Oh3vURu8Meta?.redirect,
    component: () => import("/var/www/portal/pages/auth/register/personne.vue").then(m => m.default || m)
  },
  {
    name: _91code_936CYXRyDrIuMeta?.name ?? "cart-checkout-code",
    path: _91code_936CYXRyDrIuMeta?.path ?? "/cart/checkout/:code()",
    meta: _91code_936CYXRyDrIuMeta || {},
    alias: _91code_936CYXRyDrIuMeta?.alias || [],
    redirect: _91code_936CYXRyDrIuMeta?.redirect,
    component: () => import("/var/www/portal/pages/cart/checkout/[code].vue").then(m => m.default || m)
  },
  {
    name: indexoFBj88vIa0Meta?.name ?? "cart",
    path: indexoFBj88vIa0Meta?.path ?? "/cart",
    meta: indexoFBj88vIa0Meta || {},
    alias: indexoFBj88vIa0Meta?.alias || [],
    redirect: indexoFBj88vIa0Meta?.redirect,
    component: () => import("/var/www/portal/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexbTar8Uc5RDMeta?.name ?? "certificates",
    path: indexbTar8Uc5RDMeta?.path ?? "/certificates",
    meta: indexbTar8Uc5RDMeta || {},
    alias: indexbTar8Uc5RDMeta?.alias || [],
    redirect: indexbTar8Uc5RDMeta?.redirect,
    component: () => import("/var/www/portal/pages/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: indexWPTMp3zRjmMeta?.name ?? "document-library",
    path: indexWPTMp3zRjmMeta?.path ?? "/document-library",
    meta: indexWPTMp3zRjmMeta || {},
    alias: indexWPTMp3zRjmMeta?.alias || [],
    redirect: indexWPTMp3zRjmMeta?.redirect,
    component: () => import("/var/www/portal/pages/document-library/index.vue").then(m => m.default || m)
  },
  {
    name: indexum74pTkUfeMeta?.name ?? "file-status",
    path: indexum74pTkUfeMeta?.path ?? "/file-status",
    meta: indexum74pTkUfeMeta || {},
    alias: indexum74pTkUfeMeta?.alias || [],
    redirect: indexum74pTkUfeMeta?.redirect,
    component: () => import("/var/www/portal/pages/file-status/index.vue").then(m => m.default || m)
  },
  {
    name: helloworld7wctF5FPt5Meta?.name ?? "helloworld",
    path: helloworld7wctF5FPt5Meta?.path ?? "/helloworld",
    meta: helloworld7wctF5FPt5Meta || {},
    alias: helloworld7wctF5FPt5Meta?.alias || [],
    redirect: helloworld7wctF5FPt5Meta?.redirect,
    component: () => import("/var/www/portal/pages/helloworld.vue").then(m => m.default || m)
  },
  {
    name: indexQMRukbV2hfMeta?.name ?? "inbox",
    path: indexQMRukbV2hfMeta?.path ?? "/inbox",
    meta: indexQMRukbV2hfMeta || {},
    alias: indexQMRukbV2hfMeta?.alias || [],
    redirect: indexQMRukbV2hfMeta?.redirect,
    component: () => import("/var/www/portal/pages/inbox/index.vue").then(m => m.default || m)
  },
  {
    name: indexE55kDS4rA7Meta?.name ?? "membres",
    path: indexE55kDS4rA7Meta?.path ?? "/membres",
    meta: indexE55kDS4rA7Meta || {},
    alias: indexE55kDS4rA7Meta?.alias || [],
    redirect: indexE55kDS4rA7Meta?.redirect,
    component: () => import("/var/www/portal/pages/membres/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936zU1Kt45K9Meta?.name ?? "my-cars-copy-id",
    path: _91id_936zU1Kt45K9Meta?.path ?? "/my-cars-copy/:id()",
    meta: _91id_936zU1Kt45K9Meta || {},
    alias: _91id_936zU1Kt45K9Meta?.alias || [],
    redirect: _91id_936zU1Kt45K9Meta?.redirect,
    component: () => import("/var/www/portal/pages/my-cars-copy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9333c4Xkm9jsMeta?.name ?? "my-cars-id",
    path: _91id_9333c4Xkm9jsMeta?.path ?? "/my-cars/:id()",
    meta: _91id_9333c4Xkm9jsMeta || {},
    alias: _91id_9333c4Xkm9jsMeta?.alias || [],
    redirect: _91id_9333c4Xkm9jsMeta?.redirect,
    component: () => import("/var/www/portal/pages/my-cars/[id].vue").then(m => m.default || m)
  },
  {
    name: indexREVjwr48C6Meta?.name ?? "my-cars",
    path: indexREVjwr48C6Meta?.path ?? "/my-cars",
    meta: indexREVjwr48C6Meta || {},
    alias: indexREVjwr48C6Meta?.alias || [],
    redirect: indexREVjwr48C6Meta?.redirect,
    component: () => import("/var/www/portal/pages/my-cars/index.vue").then(m => m.default || m)
  },
  {
    name: profileEzyZIzFBpeMeta?.name ?? "profile",
    path: profileEzyZIzFBpeMeta?.path ?? "/profile",
    meta: profileEzyZIzFBpeMeta || {},
    alias: profileEzyZIzFBpeMeta?.alias || [],
    redirect: profileEzyZIzFBpeMeta?.redirect,
    component: () => import("/var/www/portal/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexUPWqrteaThMeta?.name ?? "purchases",
    path: indexUPWqrteaThMeta?.path ?? "/purchases",
    meta: indexUPWqrteaThMeta || {},
    alias: indexUPWqrteaThMeta?.alias || [],
    redirect: indexUPWqrteaThMeta?.redirect,
    component: () => import("/var/www/portal/pages/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Trh89YmDp2Meta?.name ?? "services-depot-de-titre-id",
    path: _91id_93Trh89YmDp2Meta?.path ?? "/services/depot-de-titre/:id()",
    meta: _91id_93Trh89YmDp2Meta || {},
    alias: _91id_93Trh89YmDp2Meta?.alias || [],
    redirect: _91id_93Trh89YmDp2Meta?.redirect,
    component: () => import("/var/www/portal/pages/services/depot-de-titre/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_939wtSz3tj6gMeta?.name ?? "services-depot-de-titre-edit-id",
    path: _91id_939wtSz3tj6gMeta?.path ?? "/services/depot-de-titre/edit/:id()",
    meta: _91id_939wtSz3tj6gMeta || {},
    alias: _91id_939wtSz3tj6gMeta?.alias || [],
    redirect: _91id_939wtSz3tj6gMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/depot-de-titre/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_935kpKKURescMeta?.name ?? "services-immatriculation-number-prestige-id",
    path: _91id_935kpKKURescMeta?.path ?? "/services/immatriculation-number-prestige/:id()",
    meta: _91id_935kpKKURescMeta || {},
    alias: _91id_935kpKKURescMeta?.alias || [],
    redirect: _91id_935kpKKURescMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-number-prestige/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TXGmDIokVtMeta?.name ?? "services-immatriculation-number-prestige-edit-id",
    path: _91id_93TXGmDIokVtMeta?.path ?? "/services/immatriculation-number-prestige/edit/:id()",
    meta: _91id_93TXGmDIokVtMeta || {},
    alias: _91id_93TXGmDIokVtMeta?.alias || [],
    redirect: _91id_93TXGmDIokVtMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-number-prestige/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KnjSshL9hXMeta?.name ?? "services-immatriculation-number-id",
    path: _91id_93KnjSshL9hXMeta?.path ?? "/services/immatriculation-number/:id()",
    meta: _91id_93KnjSshL9hXMeta || {},
    alias: _91id_93KnjSshL9hXMeta?.alias || [],
    redirect: _91id_93KnjSshL9hXMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-number/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93xzCf0N5YTrMeta?.name ?? "services-immatriculation-number-edit-id",
    path: _91id_93xzCf0N5YTrMeta?.path ?? "/services/immatriculation-number/edit/:id()",
    meta: _91id_93xzCf0N5YTrMeta || {},
    alias: _91id_93xzCf0N5YTrMeta?.alias || [],
    redirect: _91id_93xzCf0N5YTrMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-number/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93fB6FaKaV8CMeta?.name ?? "services-immatriculation-prestige-label-id",
    path: _91id_93fB6FaKaV8CMeta?.path ?? "/services/immatriculation-prestige-label/:id()",
    meta: _91id_93fB6FaKaV8CMeta || {},
    alias: _91id_93fB6FaKaV8CMeta?.alias || [],
    redirect: _91id_93fB6FaKaV8CMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-prestige-label/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YOLDHfBNsoMeta?.name ?? "services-immatriculation-prestige-label-edit-id",
    path: _91id_93YOLDHfBNsoMeta?.path ?? "/services/immatriculation-prestige-label/edit/:id()",
    meta: _91id_93YOLDHfBNsoMeta || {},
    alias: _91id_93YOLDHfBNsoMeta?.alias || [],
    redirect: _91id_93YOLDHfBNsoMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-prestige-label/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93LrJP3kvLwSMeta?.name ?? "services-immatriculation-prestige-numero-label-id",
    path: _91id_93LrJP3kvLwSMeta?.path ?? "/services/immatriculation-prestige-numero-label/:id()",
    meta: _91id_93LrJP3kvLwSMeta || {},
    alias: _91id_93LrJP3kvLwSMeta?.alias || [],
    redirect: _91id_93LrJP3kvLwSMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-prestige-numero-label/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kBTAA0gpFmMeta?.name ?? "services-immatriculation-prestige-numero-label-edit-id",
    path: _91id_93kBTAA0gpFmMeta?.path ?? "/services/immatriculation-prestige-numero-label/edit/:id()",
    meta: _91id_93kBTAA0gpFmMeta || {},
    alias: _91id_93kBTAA0gpFmMeta?.alias || [],
    redirect: _91id_93kBTAA0gpFmMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/immatriculation-prestige-numero-label/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93O7NIMDbWc1Meta?.name ?? "services-mutation-id",
    path: _91id_93O7NIMDbWc1Meta?.path ?? "/services/mutation/:id()",
    meta: _91id_93O7NIMDbWc1Meta || {},
    alias: _91id_93O7NIMDbWc1Meta?.alias || [],
    redirect: _91id_93O7NIMDbWc1Meta?.redirect,
    component: () => import("/var/www/portal/pages/services/mutation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uxRQlqyWlMMeta?.name ?? "services-mutation-edit-id",
    path: _91id_93uxRQlqyWlMMeta?.path ?? "/services/mutation/edit/:id()",
    meta: _91id_93uxRQlqyWlMMeta || {},
    alias: _91id_93uxRQlqyWlMMeta?.alias || [],
    redirect: _91id_93uxRQlqyWlMMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/mutation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93f7cAdibQNYMeta?.name ?? "services-reimmatriculation-id",
    path: _91id_93f7cAdibQNYMeta?.path ?? "/services/reimmatriculation/:id()",
    meta: _91id_93f7cAdibQNYMeta || {},
    alias: _91id_93f7cAdibQNYMeta?.alias || [],
    redirect: _91id_93f7cAdibQNYMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/reimmatriculation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93whL2AYqY07Meta?.name ?? "services-reimmatriculation-edit-id",
    path: _91id_93whL2AYqY07Meta?.path ?? "/services/reimmatriculation/edit/:id()",
    meta: _91id_93whL2AYqY07Meta || {},
    alias: _91id_93whL2AYqY07Meta?.alias || [],
    redirect: _91id_93whL2AYqY07Meta?.redirect,
    component: () => import("/var/www/portal/pages/services/reimmatriculation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93U923wSDH3fMeta?.name ?? "services-reprise-de-titre-id",
    path: _91id_93U923wSDH3fMeta?.path ?? "/services/reprise-de-titre/:id()",
    meta: _91id_93U923wSDH3fMeta || {},
    alias: _91id_93U923wSDH3fMeta?.alias || [],
    redirect: _91id_93U923wSDH3fMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/reprise-de-titre/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93EjbPoR6MU5Meta?.name ?? "services-reprise-de-titre-edit-id",
    path: _91id_93EjbPoR6MU5Meta?.path ?? "/services/reprise-de-titre/edit/:id()",
    meta: _91id_93EjbPoR6MU5Meta || {},
    alias: _91id_93EjbPoR6MU5Meta?.alias || [],
    redirect: _91id_93EjbPoR6MU5Meta?.redirect,
    component: () => import("/var/www/portal/pages/services/reprise-de-titre/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936HaLVgfxrZMeta?.name ?? "services-sales-declaration-id",
    path: _91id_936HaLVgfxrZMeta?.path ?? "/services/sales-declaration/:id()",
    meta: _91id_936HaLVgfxrZMeta || {},
    alias: _91id_936HaLVgfxrZMeta?.alias || [],
    redirect: _91id_936HaLVgfxrZMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/sales-declaration/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93sEHlqRbPPNMeta?.name ?? "services-sales-declaration-edit-id",
    path: _91id_93sEHlqRbPPNMeta?.path ?? "/services/sales-declaration/edit/:id()",
    meta: _91id_93sEHlqRbPPNMeta || {},
    alias: _91id_93sEHlqRbPPNMeta?.alias || [],
    redirect: _91id_93sEHlqRbPPNMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/sales-declaration/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ydRuW5h4bKMeta?.name ?? "services-vehicule-transformation-id",
    path: _91id_93ydRuW5h4bKMeta?.path ?? "/services/vehicule-transformation/:id()",
    meta: _91id_93ydRuW5h4bKMeta || {},
    alias: _91id_93ydRuW5h4bKMeta?.alias || [],
    redirect: _91id_93ydRuW5h4bKMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/vehicule-transformation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93BSxlhk86SMMeta?.name ?? "services-vehicule-transformation-edit-id",
    path: _91id_93BSxlhk86SMMeta?.path ?? "/services/vehicule-transformation/edit/:id()",
    meta: _91id_93BSxlhk86SMMeta || {},
    alias: _91id_93BSxlhk86SMMeta?.alias || [],
    redirect: _91id_93BSxlhk86SMMeta?.redirect,
    component: () => import("/var/www/portal/pages/services/vehicule-transformation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: facturesqpys2kjqyeMeta?.name ?? "transactions-factures",
    path: facturesqpys2kjqyeMeta?.path ?? "/transactions/factures",
    meta: facturesqpys2kjqyeMeta || {},
    alias: facturesqpys2kjqyeMeta?.alias || [],
    redirect: facturesqpys2kjqyeMeta?.redirect,
    component: () => import("/var/www/portal/pages/transactions/factures.vue").then(m => m.default || m)
  },
  {
    name: indexzH9QjGEbcjMeta?.name ?? "transactions",
    path: indexzH9QjGEbcjMeta?.path ?? "/transactions",
    meta: indexzH9QjGEbcjMeta || {},
    alias: indexzH9QjGEbcjMeta?.alias || [],
    redirect: indexzH9QjGEbcjMeta?.redirect,
    component: () => import("/var/www/portal/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: paiementstUt64tkdlWMeta?.name ?? "transactions-paiements",
    path: paiementstUt64tkdlWMeta?.path ?? "/transactions/paiements",
    meta: paiementstUt64tkdlWMeta || {},
    alias: paiementstUt64tkdlWMeta?.alias || [],
    redirect: paiementstUt64tkdlWMeta?.redirect,
    component: () => import("/var/www/portal/pages/transactions/paiements.vue").then(m => m.default || m)
  }
]